@font-face {
  font-family: BoseText;
  src: url("data/fonts/BoseText-Regular.otf")
}

@font-face {
  font-family: BoseText;
  font-weight: bold;
  src: url("data/fonts/BoseText-Bold.otf")
}

@font-face {
  font-family: BoseText-JP;
  src: url("data/fonts/BoseText-Regular-JP.ttf");
}

@font-face {
  font-family: BoseText-ZH;
  src: url("data/fonts/BoseText-Regular-ZH.ttf")
}

@font-face {
  font-family: BoseText-ZH;
  font-weight: bold;
  src: url("data/fonts/BoseText-Bold-ZH.ttf")
}



html,
#root {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
}
body {
  background: rgb(248, 248, 248);
  font-family: BoseText;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  justify-content: center;
}
.container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  /* max-width: 1000px;
  max-height: 800px; */
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
@media (max-width: 600px) {
  .blocker {
    height: 280px!important;
  }
  .colorPicker {
    //bottom: 60px!important;
    bottom: 2px!important;
  }
  canvas {
    margin-top: -60px!important;
  }
}

.note {
  font-size: 14px!important;
  position: absolute;
  top:-60px;
  left: 50%;
  opacity: 0.7;
  transform: translate(-50%, -0%);
  text-shadow: 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white;
}
.blocker {
  position: absolute;
  width: 100vw;
  z-index: 1;
  height:170px;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 21%, rgba(0,212,255,0) 100%);
  bottom:0px;
}
@media (max-width: 600px) {
  h3 {

    font-size: 1rem!important;
  }

  .colorPicker {
    text-align: center;
    position: absolute;
    // left: 5px!important;
    // width: 0!important;
    max-width: 850px;
    // transform: translate(-50%, -50%);
    bottom: 5px;
    z-index: 9;
  }
  // .colorOptions {
  //   justify-content: flex-start!important;
  // }
  // .optionWrapper {
  //   flex-direction: column;
  // }
  // .mainOption {
  //   border-left: solid black 3px;
  //   border-top: solid transparent 0px!important;
  //   padding-left:20px;
  // }
  // .xlr {
  //   border-left: solid black 3px;
  //   border-top: solid transparent 0px!important;
  //   padding-left:20px;
  // }
  // .ts {
  //   border-left: solid black 3px;
  //   border-top: solid transparent 0px!important;
  //   padding-left:20px;
  // }
  
}
.optionWrapper {
  padding-left:20px;
  padding-right:20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.mainOption {
  border-top: solid black 3px;
  margin-right: 35px;
  padding-top: 15px;
  width: 33%;
}
.xlr {
  border-top: solid black 3px;
}
.xlr:hover {
  opacity: 1!important;
  transition: 0.3s;
}
.ts:hover {
  opacity: 1!important;
  transition: 0.3s;
}
.ts {
  border-top: solid black 3px;
}

h1 {
  position: absolute;
  top: 43px;
  left: 140px;
  padding: 0;
  margin: 40px;
  font-size: 5em;
  line-height: 0.7em;
  letter-spacing: -6px;
  color: #272730;
}
h3 {
  font-weight: 300;
  line-height: 1.15;
  margin: 0;
  text-align: left;
  text-shadow: 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white;
  font-size: 1.1rem;
}
.colorPicker {
  text-align: center;
  position: absolute;
  left: 50%;
  width: 100%;
  max-width: 850px;
  transform: translate(-50%, -33%);
  bottom: 5px;
  z-index: 9;
}
.closeButton img {
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 10px !important;
}
.closeButton {
  z-index: 3;
  position: absolute !important;
  right: 13px;
  height: 40px;
  width: 40px;
  top: 20px;
  cursor: pointer;
  background-color: rgb(211, 211, 211);
  border-radius: 50%;
}
.colorOptions {
  width: 100%;
  padding-top: 10px;
  display: flex;
  user-select: none;
  justify-content: center;
}
.colorOuter {
  transition: ease-in-out 0.15s;
  margin-left: 2px;
  margin-right: 2px;
  cursor: pointer;
  position: relative;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: rgb(250, 250, 250);
}
.colorInner {
  position: absolute;
  width: 30px;
  cursor: pointer;
  box-sizing: border-box;
  height: 30px;
  margin: 3px 0px 0px 3px;
  border: solid 1px rgb(224, 224, 224);
  border-radius: 50%;
}
.handInstruction {
  width: 45px;
  pointer-events: none;
  animation-duration: 2s;
  transition: opacity 1s ease-in-out;
  height: 45px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.handInstruction svg {
  animation: shake 3.5s ease;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
@keyframes shake {
  0% {
    transform: translateX(0) scale(1);
    opacity: 0;
  }

  20% {
    transform: translateX(-30px);
    opacity: 1;
  }
  40% {
    transform: translateX(30px);
    opacity: 1;
  }

  60% {
    transform: translateX(-30px);
  }

  80% {
    transform: translateX(30px);
  }
  90% {
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 0;
  }
}
.privacyOverlay-shown {
  visibility: visible;
  opacity: 1;
  transition: all 200ms;
  -webkit-transition: all 200ms linear;
}
.privacyOverlay-hidden {
  visibility: hidden;
  opacity: 0;
  transition: all 200ms linear;
  -webkit-transition: all 200ms linear;
}
.langOverlay-shown {
  visibility: visible;
  opacity: 1;
  transition: all 200ms;
  -webkit-transition: all 200ms linear;
}
.langOverlay-hidden {
  visibility: hidden;
  opacity: 0;
  transition: all 200ms linear;
  -webkit-transition: all 200ms linear;
}
@media (max-width: 900px) {
  .privacyOverlay {
    h1 {
      font-size: 22px !important;
    }
  }
}

.privacyOverlay {
  button {
    background-color: #181818;
    color: #f8f8f8;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.2px;
    line-height: 14px;
    border-radius: 180px;
    border: none;
    -webkit-appearance: none;
    padding: 12px 25px;
    cursor: pointer;
    user-select: none;
    bottom: 5px;
  }
  h1 {
    padding: 23px 0px 23px 0px;
    width: 100%;
    letter-spacing: normal;
    text-align: center;
    position: fixed;
    top: 0px;
    margin: 0;
    left: 50%;
    top: 36px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-weight: 600;
    background-color: #fff;
    z-index: 00;
    @media (max-width: 1280px) {
      width: 100%;
    }
  }
  h2 {
    font-weight: 400;
  }
  a {
    color: blue !important;
    text-decoration: underline;
  }
  .privacyHide {
    height: 20px;
    width: 20px;
    z-index: 600;
    margin-left: 20px;
    top: 32px;
    position: fixed;
    cursor: pointer;
    @media (max-width: 800px) {
      top: 28px;
      margin: 0px !important;
    }
  }
  button {
    margin: 0px 0px 10px 0px;
    position: relative;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
.privacyOverlay {
  position: absolute;
  color: #000;
  background-color: #fff;
  width: 100%;
  top: 0px;
  height: 100%;
  z-index: 99999;
  overflow-y: scroll;

  h1 {
    font-size: 26px;
    letter-spacing: normal;
  }

  h2 {
    font-size: 24px;
  }
  svg {
    -webkit-transform: rotate(-90deg); /* Safari and Chrome */
    -moz-transform: rotate(-90deg); /* Firefox */
    -ms-transform: rotate(-90deg); /* IE 9 */
    -o-transform: rotate(-90deg); /* Opera */
    transform: rotate(-90deg);
  }
  .policy {
    padding: 60px 30px 30px 30px;
  }
  @media (max-width: 1500px) {
    .langOverlayHide {
      left: 25px;
      padding: 5px;
      background-color: #f8f8f8;
      border-radius: 180px;
    }

    .privacyHide {
      left: 25px;
      background-color: #f8f8f8;
      border-radius: 180px;
    }
  }
}
.activeWrapper {
  position: sticky;
  top: 0px;
  width: 100%;
  padding-bottom: 30px;
  background-color: #f4f4f4;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 525px) {
    grid-template-columns: repeat(1, 1fr);
  }

  form {
    background: transparent;
  }
}
.activeLanguage {
  color: #000;
  position: relative;
  font-weight: 700;
  padding-top: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 220px;
  margin: 0 auto;
  text-transform: uppercase;
  @media (max-width: 1000px) {
    padding-left: 20px;
  }
}
.langOverlayHide {
  position: absolute;
  margin-left: 20px;
  z-index: 800;
  @media (max-width: 800px) {
    margin-left: 0px !important;
  }
}
.langOverlay {
  position: absolute;
  background-color: #fff;
  width: 100%;
  right: 0px;
  top: 0px;
  height: 100%;
  z-index: 99999;
  overflow-y: scroll;

  h1 {
    font-size: 26px;
  }
  .langOverlayHide {
    height: 20px;
    width: 20px;
    top: 32px;
    cursor: pointer;
    @media (max-width: 800px) {
      top: 28px;
      padding: 0px !important;
      position: fixed;
    }
  }

  h2 {
    font-size: 24px;
  }
  svg {
    -webkit-transform: rotate(-90deg); /* Safari and Chrome */
    -moz-transform: rotate(-90deg); /* Firefox */
    -ms-transform: rotate(-90deg); /* IE 9 */
    -o-transform: rotate(-90deg); /* Opera */
    transform: rotate(-90deg);
  }

  @media (max-width: 800px) {
    position: fixed;
    height: 100%;
    width: 100%;

    .policy {
      padding: 60px 30px 30px 30px;
    }

    .langOverlayHide {
      left: 25px;
      padding: 5px;
      background-color: #f8f8f8;
      border-radius: 180px;
    }
  }
}
.langOverlay {
  h1 {
    left: 50%;
    transform: translateX(-50%);
    padding: 23px 200px 23px 200px;
    width: 80%;
    text-align: center;
    position: fixed;
    top: 0px;
    margin: 0;
    background-color: #fff;
    z-index: 500;
  }
  h2 {
    font-weight: 400;
  }
  button {
    margin: 45px 0px 10px 0px;
    position: relative;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.languages {
  button {
    background-color: rgb(24, 24, 24);
    color: rgb(248, 248, 248);
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.2px;
    line-height: 14px;
    border-radius: 180px;
    border: none;
    appearance: none;
    padding: 12px 25px;
    cursor: pointer;
    user-select: none;
    bottom: 5px;
  }
  .searchBar {
    position: relative;
    display: flex;
    width: 190px;
    margin: 0 auto;
    margin-top: 17px;
    min-width: 100px;

    input {
      padding: 11px;
      background: transparent;
      border-left: 1px solid;
      border-width: 0px 0px 1px 0;
      border-radius: 0px;
    }

    span {
      position: absolute;
      width: 25px;
      height: 25px;
      right: 0px;
      top: 8px;

      input {
        color: #757575;
        -webkit-text-stroke: 3px #f4f4f4;
        cursor: pointer;
        border: none;
        background-image: none;
        background-color: transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        font-size: 25px;
        padding: 0px;
      }
      input:focus {
        border: black 2px solid;
      }
    }
  }
  input {
    background-color: transparent;
    font-size: 18px;
    width: 100%;
  }
  .search-icon {
    position: absolute;
    top: 6px;
    left: 8px;
    width: 14px;
  }

  .clear-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 10px;
    cursor: pointer;
  }

  .listLang {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    cursor: pointer;

    span {
      color: #000;
      font-weight: 400;
      text-transform: uppercase;
      width: 170px;
    }
  }
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    padding: 0;
    margin: 0px 0px 40px 0px;
  }
  @media (max-width: 1000px) {
    ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 525px) {
    ul {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.bottomBar {
  justify-content: space-between;
  z-index: 9;
  position: absolute;
  bottom: 0px;
  height: 50px;
  width: 100%;
  // padding-left: 45px;
  // padding-right: 45px;
  background-color: rgb(229, 229, 229);
  .languageSelector {
    cursor: pointer;
  }
  .languageSelector {
    img {
      height: 20px;
      width: 30px;
      margin-left: 5px;
    }
    h2 {
      text-decoration: underline;
    }
  }
  .privacyButton {
    cursor: pointer;
    h2 {
      text-decoration: underline;
    }
  }
  .poweredBy {
    h2 {
      font-weight: 800 !important;
    }
  }
  .bottomContent {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
  }
  .p {
    margin-left: 45px;
  }
  h2 {
    font-weight: 500;
    color: #191919;
    font-size: 16px;
  }
  @media (max-width: 800px) {
    // padding-left: 15px;
    // padding-right: 15px;
    h2 {
      font-size: 14px;
    }
  }
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  width: 45%;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}
.bose-localeSelector {
  max-width: 1280px;
  font-size: 14px;
  padding: 20px 30px;
}
.bose-richText {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6em;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin: 20px 30px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .bose-richText {
    margin: 20px 20px;
  }
}

.bose-richText--fullWidth {
  margin: 20px 0;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .bose-richText {
    font-size: 14px;
  }
}

.bose-richText b {
  font-style: normal;
  font-weight: 700;
}

.bose-richText b i {
  font-style: italic;
}

.bose-richText i {
  font-style: italic;
  font-weight: 400;
}

.bose-richText i b {
  font-weight: 700;
}

.bose-richText ol,
.bose-richText ul {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15px;
  color: #666;
}

.bose-richText ol li,
.bose-richText ul li {
  line-height: 1.6em;
}

.bose-richText ul li {
  padding: 0 0 0.5em 0;
  list-style-type: disc;
}

.bose-richText ol li {
  padding: 0 0 0.5em 0;
  list-style-type: decimal;
}

.bose-richText div,
.bose-richText p,
.bose-richText span {
  padding-top: 1.6em;
  line-height: 1.6em;
}

.bose-richText div:first-child,
.bose-richText p:first-child,
.bose-richText span:first-child {
  padding-top: 0;
}

.bose-richText.-marginLeftZero {
  margin-left: 0;
}

.bose-richText.-marginLeftMedium {
  margin-left: 10%;
}

.bose-richText.-marginLeftMediumX {
  margin-left: 7.5%;
}

.bose-richText.-marginLeftSmall {
  margin-left: 5%;
}

.bose-richText.-marginLeftLarge {
  margin-left: 12.5%;
}

.bose-richText.-marginLeftLargeX {
  margin-left: 15%;
}

.bose-richText.-marginRightZero {
  margin-right: 0;
}

.bose-richText.-marginRightMedium {
  margin-right: 10%;
}

.bose-richText.-marginRightMediumX {
  margin-right: 7.5%;
}

.bose-richText.-marginRightSmall {
  margin-right: 5%;
}

.bose-richText.-marginRightLarge {
  margin-right: 12.5%;
}

.bose-richText.-marginRightLargeX {
  margin-right: 15%;
}

.bose-richText.-marginTopZero {
  margin-top: 0;
}

.bose-richText.-marginTopMedium {
  margin-top: 32px;
}

.bose-richText.-marginTopSmall {
  margin-top: 24px;
}

.bose-richText.-marginTopLarge {
  margin-top: 48px;
}

.bose-richText.-marginTopLargeX {
  margin-top: 64px;
}

.bose-richText.-marginTopDefault {
  margin-top: 16px;
}

.bose-richText.-marginBottomZero {
  margin-bottom: 0;
}

.bose-richText.-marginBottomMedium {
  margin-bottom: 32px;
}

.bose-richText.-marginBottomSmall {
  margin-bottom: 24px;
}

.bose-richText.-marginBottomLarge {
  margin-bottom: 48px;
}

.bose-richText.-marginBottomLargeX {
  margin-bottom: 64px;
}

.bose-richText.-marginBottomDefault {
  margin-bottom: 0;
}

.bose-richText.-marginBottomExtraSmall {
  margin-bottom: 16px;
}

.bose-regionChanger .bose-regionChanger__region {
  display: block;
  margin-bottom: 54px;
  font-size: 26px;
  color: #262626;
  text-align: center;
}

.bose-regionChanger .bose-regionChanger__region:hover {
  color: #a6a6a6;
}

.bose-regionChanger .bose-regionChanger__countries {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-height: 300px;
}

.bose-regionChanger .bose-regionChanger__countries .bose-regionChanger__country {
  display: block;
  margin-bottom: 19px;
  font-size: 22px;
  color: #262626;
  text-align: center;
}

.bose-regionChanger .bose-regionChanger__countries .bose-regionChanger__country:hover {
  color: #a6a6a6;
}

.bose-regionChanger .bose-regionChanger__backButton {
  position: absolute;
  top: 28px;
  font-size: 20px;
  color: #262626;
}

.bose-regionChanger .bose-regionChanger__backButton:hover {
  color: #a6a6a6;
}

.bose-regionChanger .bose-regionChanger__backButton::before {
  line-height: 1em;
  background-repeat: no-repeat;
  -o-background-size: 105em 125em;
  background-size: 105em 125em;
  background-position: -34em -22em;
  width: 2em;
  height: 2em;
  position: absolute;
  top: 3px;
  left: -26px;
  font-size: 7px;
  content: "";
}

.bose-regionChanger .bose-regionChanger__closeButton {
  float: right;
}

.bose-regionChanger .bose-regionChanger__closeButton::after {
  line-height: 1em;
  background-repeat: no-repeat;
  -o-background-size: 105em 125em;
  background-size: 105em 125em;
  background-position: -34em -22em;
  width: 2em;
  height: 2em;
  position: absolute;
  top: 28px;
  font-size: 12px;
  content: "";
}

.bose-messagePanel {
  max-width: 1280px;
  min-height: 500px;
  margin-top: 300px;
  text-align: center;
}

@media screen and (min-width: 0) and (max-width: 1279px) {
  .bose-messagePanel {
    min-height: 300px;
    margin-top: 100px;
  }
}

.bose-messagePanel .bose-messagePanel__title {
  margin-bottom: 2px;
  font-size: 60px;
  color: #000;
}

.bose-messagePanel .bose-messagePanel__message {
  margin-bottom: 12px;
  font-size: 30px;
}

.bose-messagePanel .bose-messagePanel__redirect {
  font-size: 20px;
  color: #0d7bab;
}

div.image.pageHeader {
  margin-bottom: 0;
}

.bose-pageHeader {
  position: relative;
  min-height: 140px;
  overflow: hidden;
}

.bose-pageHeader .bose-pageHeader__textWrapper {
  position: absolute;
  margin-top: 0;
  margin-bottom: 8px;
}

.bose-pageHeader .bose-pageHeader__textWrapper h1 {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 300;
  color: #333;
  text-transform: uppercase;
}

.bose-pageHeader .bose-pageHeader__textWrapper.-darkFont,
.bose-pageHeader .bose-pageHeader__textWrapper.-darkFont h1,
.bose-pageHeader .bose-pageHeader__textWrapper.-darkFont h2 {
  color: #000;
}

.bose-pageHeader .bose-pageHeader__textWrapper.-lightFont,
.bose-pageHeader .bose-pageHeader__textWrapper.-lightFont h1,
.bose-pageHeader .bose-pageHeader__textWrapper.-lightFont h2 {
  color: #fff;
}

@media screen and (min-width: 0) and (max-width: 1279px) {
  .bose-pageHeader .bose-pageHeader__textWrapper.-lightFont,
  .bose-pageHeader .bose-pageHeader__textWrapper.-lightFont h1,
  .bose-pageHeader .bose-pageHeader__textWrapper.-lightFont h2 {
    color: #000;
  }
}

.bose-pageHeader .bose-pageHeader__textWrapper.-bottomLeft,
.bose-pageHeader .bose-pageHeader__textWrapper.-topLeft {
  left: 0;
  padding-left: 20px;
}

@media screen and (min-width: 1280px) {
  .bose-pageHeader .bose-pageHeader__textWrapper.-bottomLeft,
  .bose-pageHeader .bose-pageHeader__textWrapper.-topLeft {
    left: 0;
    padding-left: 84px;
  }
}

.bose-pageHeader .bose-pageHeader__textWrapper.-bottomRight,
.bose-pageHeader .bose-pageHeader__textWrapper.-topRight {
  right: 0;
  padding-right: 20px;
  text-align: right;
}

.bose-pageHeader .bose-pageHeader__textWrapper.-topLeft,
.bose-pageHeader .bose-pageHeader__textWrapper.-topRight {
  top: 105px;
}

.bose-pageHeader .bose-pageHeader__textWrapper.-bottomLeft,
.bose-pageHeader .bose-pageHeader__textWrapper.-bottomRight {
  bottom: 20px;
}

@media screen and (min-width: 0) and (max-width: 1279px) {
  .bose-pageHeader .bose-pageHeader__textWrapper.-bottomLeft,
  .bose-pageHeader .bose-pageHeader__textWrapper.-bottomRight,
  .bose-pageHeader .bose-pageHeader__textWrapper.-topLeft,
  .bose-pageHeader .bose-pageHeader__textWrapper.-topRight {
    position: relative;
    top: 0;
    bottom: 0;
    margin-top: 10px;
  }
}

.bose-pageHeader .bose-adaptiveImage {
  min-height: 140px;
  margin: 0 !important;
}

.bose-pageHeader .bose-adaptiveImage.bose-adaptiveImage--notFound {
  position: relative;
  z-index: 100;
}

.bose-pageHeader p.bose-h2 {
  margin: 14px 0;
}

.bose-pageHeader .bose-pageHeader__backgroundContainer {
  z-index: 0;
  width: 100%;
  background-repeat: no-repeat;
  -o-background-size: cover;
  background-size: cover;
}

@media screen and (min-width: 768px) {
  .bose-pageHeader .bose-pageHeader__backgroundContainer {
    height: 400px;
  }
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .bose-pageHeader .bose-pageHeader__backgroundContainer {
    height: 300px;
  }
}

.bose-pageHeader .bose-pageHeader__backgroundContainer.-leftFocalRegion {
  background-position-x: 0;
}

.bose-pageHeader .bose-pageHeader__backgroundContainer.-rightFocalRegion {
  background-position-x: 100%;
}

.bose-pageHeader .bose-pageHeader__backgroundContainer.-centerFocalRegion {
  background-position-x: 50%;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .bose-pageHeader .bose-pageHeader__backgroundContainer picture {
    display: none;
  }
}

.bose-pageHeader .bose-pageHeader__backgroundContainer picture img {
  width: 100%;
}

.bose-panel {
  padding: 15px;
  margin: 10px;
  height: 150px;
  overflow: auto;
}
.bose-panel::after {
  content: "";
  display: table;
  clear: both;
}

.bose-panel.bose-panel--overflowHidden {
  overflow: hidden;
}

.bose-panel.bose-panel--centered {
  text-align: center;
}

.bose-panel.bose-panel--noSpacing {
  padding: 0;
  margin: 0;
}

.bose-panel--middle .bose-panel__content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column nowrap;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.bose-panel--bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  min-height: 200px;
}

.bose-panel--bottom .bose-panel__content {
  width: inherit;
}

.bose-panel.-transparent {
  background-color: transparent;
}

.bose-panel.-supportTransparent {
  background-color: transparent;
  border: none;
}

.bose-panel.-black {
  background-color: #000;
}

.bose-panel.-supportBlack {
  background-color: #fff;
  border: 1px solid #999;
}

.bose-panel.-boseBlack {
  background-color: #242424;
}

.bose-panel.-white {
  background-color: #fff;
}

.bose-panel.-boseWhite {
  background-color: #f8f8f8;
}

.bose-panel.-boseWhiteSupport {
  width: 90%;
  padding: 15px 0;
  margin: 0 0 10% 5%;
  overflow: auto;
  background-color: #fff;
  border: 1px solid #999;
}

@media screen and (min-width: 768px) {
  .bose-panel.-boseWhiteSupport {
    width: 70%;
    padding: 20px;
    margin: 0 0 25px 30px;
    overflow: auto;
    background-color: #fff;
    border: 1px solid #999;
  }
}

@media screen and (min-width: 768px) {
  .parent-rowTabs {
    padding: 0 20px;
  }
}
